import { useEffect } from "react";
import { Footer } from "./Footer";
import logo2 from "assets/images/home/hammer.png";
import logo3 from "assets/images/home/power-logo.png";
import logo4 from "assets/images/home/edifyconnect.png";
import logo5 from "assets/images/home/social-traders-logo.png";
import logo6 from "assets/images/home/diverseedge-logo.png";
import { Header } from "./Header";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const ApiIntegration = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Hero Section  */}
      <Header />

      {/* secondary Hero section */}
      <div className="dark-blue-bg bg-overlay sec-hero">
        <div className="container-new">
          <h3>SocialPro Partners</h3>
          <h2 data-aos="fade-in" data-aos-duration="1000">
            Working together with the best in the business
          </h2>
          <p>
            SocialPro seamlessly integrates with leading construction, payroll
            and accounting software for effortless compatibility. Plus, we
            proudly partner with social and sustainable businesses to amplify
            your impact.
          </p>
        </div>
      </div>

      {/* cards Section  */}
      <div className="cards-wrapper api-section">
        <div className="container-new">
          <div className="cta">
            <h3>Integration partners</h3>
            <p>
              SocialPro seamlessly connects with popular construction, project
              management, ERP, and reporting software. Effortlessly integrate
              SocialPro with your existing systems for streamlined data entry
              and enhanced accuracy throughout your organisation.
            </p>
          </div>
          <div
            className="servicecards cardswrapper"
            style={{ justifyContent: "center" }}
          >
            <div className="about-card">
              <img src={logo2} />
            </div>
            <div className="about-card">
              <img src={logo3} />
            </div>
          </div>
          <div className="cta cta-last-child">
            <h3>Industry partners</h3>
            <p>
              SocialPro collaborates with businesses in the social and
              sustainability sectors, fostering partnerships to drive positive
              impact and sustainable growth strategies for your business.
            </p>
          </div>
          <div className="servicecards cardswrapper  ">
            <div className="about-card">
              <img src={logo4} />
            </div>
            <div className="about-card">
              <img src={logo5} />
            </div>
            <div className="about-card">
              <img src={logo6} />
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <Footer />
    </>
  );
};
